/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { Link as GatsbyLink } from 'gatsby';
import { PageTable } from '../components/content-modules';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { getLinkForPage } from '../utils/links';

const options = (pages, locale) => ({
  renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  renderMark: {
    [MARKS.BOLD]: text => <Styled.strong>{text}</Styled.strong>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Styled.p>{children}</Styled.p>,
    [BLOCKS.HEADING_1]: (node, children) => <Styled.h1>{children}</Styled.h1>,
    [BLOCKS.HEADING_2]: (node, children) => <Styled.h2>{children}</Styled.h2>,
    [BLOCKS.HEADING_3]: (node, children) => <Styled.h3>{children}</Styled.h3>,
    [BLOCKS.HEADING_4]: (node, children) => <Styled.h4>{children}</Styled.h4>,
    [BLOCKS.HEADING_5]: (node, children) => <Styled.h5>{children}</Styled.h5>,
    [BLOCKS.HEADING_6]: (node, children) => <Styled.h6>{children}</Styled.h6>,
    [BLOCKS.UL_LIST]: (node, children) => <Styled.ul>{children}</Styled.ul>,
    [BLOCKS.OL_LIST]: (node, children) => <Styled.ol>{children}</Styled.ol>,
    [BLOCKS.LIST_ITEM]: (node, children) => <Styled.li>{children}</Styled.li>,
    [BLOCKS.HR]: (node, children) => <hr />,
    [BLOCKS.QUOTE]: (node, children) => <Styled.blockquote>{children}</Styled.blockquote>,
    [INLINES.ENTRY_HYPERLINK]: node => {
      if (!node.data.target___NODE) return;
      let id = node.data.target___NODE;
      let referencedPage = pages.find(page => page.node.id === id);

      if (!referencedPage) {
        console.log('invalid hyperlink to graphql id:', id);
      }
      if (referencedPage) {
        return <GatsbyLink to={getLinkForPage(referencedPage.node)}>{node.content[0].value}</GatsbyLink>;
      }
    },
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const sysId = node?.data?.target?.sys?.contentType && node?.data?.target?.sys?.contentType?.sys?.id;
      switch (sysId) {
        case 'pageTable':
          return <PageTable content={node.data.target.fields.body[locale] || node.data.target.fields.body['fi-FI']} />;
        default:
          break;
      }
    },
  },
});

export default ({ content, pages, locale }) => {
  const languages = {
    sv: 'sv-SE',
    en: 'en-US',
    fi: 'fi-FI',
  };
  const language = languages[locale] || 'fi-FI';
  return renderRichText(content, options(pages, language));
};
