/** @jsx jsx */
import { jsx, Styled, Box } from 'theme-ui';
import React from 'react';
import { graphql } from 'gatsby';
import MobileRichText from '../components/MobileRichText';
import { useSelector } from 'react-redux';
import * as analytics from '../utils/analytics';

const MobilePage = ({ data }) => {
  const { title, body } = data.contentfulPage;
  analytics.usePageCategory('mobile');
  const pages = data.allContentfulPage.edges;
  const locale = useSelector(state => state.session.locale);
  return (
    <Styled.root>
      <Box sx={{ p: 3 }}>
        <Styled.h1 sx={{ mt: 0 }}>{title}</Styled.h1>
        {body && <MobileRichText content={body} pages={pages} locale={locale} />}
      </Box>
    </Styled.root>
  );
};
export default MobilePage;

export const pageQuery = graphql`
  query($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      node_locale
      body {
        raw
        references {
          ... on ContentfulAsset {
            ...assetFields
          }
          ... on ContentfulAccordion {
            ...accordionFields
          }
          # ... on ContentfulComponent {
          #   ...componentFields
          # }
          ... on ContentfulContact {
            ...contactFields
          }
          ... on ContentfulFrequentlyAskedQuestionCategory {
            ...faqCategoryFields
          }
          ... on ContentfulLayoutLink {
            ...layoutLinkFields
          }
          ... on ContentfulPageTable {
            ...tableFields
          }
        }
      }
    }
    allContentfulPage {
      edges {
        node {
          id
          contentful_id
          slug
        }
      }
    }
  }
`;
